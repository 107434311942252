import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import CategoryCard from "../components/CategoryCard";
import ContainerStyle from "../styles/ContainerStyle";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import BCContainer from "../styles/BCContainer";

const CategoryCardStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  padding: 100px 0;
  text-align: center;
  overflow: hidden;
  a {
    margin-left: -1px;
  }
`;

export default function SingleCategory({ data }) {
  const location = typeof window !== "undefined" ? window.location : "";

  return (
    <>
      <BCContainer>
        <ContainerStyle>
          <Breadcrumb
            location={location}
            crumbLabel={data.strapiCategory.name}
            crumbSeparator="/"
          />
        </ContainerStyle>
      </BCContainer>
      <CategoryCardStyle>
        <CategoryCard
          categories={data.strapiCategory.categories}
          products={data.strapiCategory.products}
        />
      </CategoryCardStyle>
    </>
  );
}

export const categoryQuery = graphql`
  query singleCategory($slug: String!) {
    strapiCategory(slug: { eq: $slug }) {
      name
      slug
      id
      products {
        id
        slug
        name
        productImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 220
                placeholder: BLURRED
                formats: AUTO
                quality: 100
              )
            }
          }
        }
      }
      categories {
        id
        name
        slug
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 220, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
      }
    }
  }
`;
