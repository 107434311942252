import { Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const CardStyle = styled(Link)`
  --red: ${({ theme: { palette } }) => palette.secondary.main};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: ${({ theme: { palette } }) => palette.primary.main};
  & {
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    &:hover button {
      background: var(--red);
      transition: background 200ms ease-in-out;
      color: white;
    }
    &:hover h3 {
      color: ${({ theme: { palette } }) => palette.secondary.main};
      transition: color 200ms ease-in-out;
    }
  }
  button {
    padding: 10px 20px;
    text-align: center;
    display: block;
    margin-top: 10px;
    border: 2px solid var(--red);
    border-radius: 10px;
    color: var(--red);
  }
  h3 {
    margin-top: 20px;
    font-size: 1rem;
    text-transform: capitalize;
  }
`;

export default function CategoryCard({ categories, products }) {
  if (!categories.length && !products.length) {
    return <h1>No categories or products found!</h1>;
  }
  return (
    <>
      {categories.map(category => {
        const isThereAnImage = !!category?.image;
        const imageGetter =
          isThereAnImage && getImage(category?.image.localFile);
        return (
          <CardStyle key={category.id} to={category.slug}>
            {isThereAnImage ? (
              <GatsbyImage
                image={imageGetter}
                alt={category?.image?.alternativeText}
              />
            ) : (
              <StaticImage
                width={220}
                height={220}
                style={{ border: "1px solid black" }}
                src="../assets/static-images/product-placeholder.jpg"
                alt="a placeholder"
              ></StaticImage>
            )}
            <h3>{category.name}</h3>
            <button>View Category</button>
          </CardStyle>
        );
      })}
      {products.map(product => {
        const isThereAnImage = !!product.productImages;
        const imageGetter =
          isThereAnImage && getImage(product?.productImages[0].localFile);
        return (
          <CardStyle key={product.id} to={`/product/${product.slug}`}>
            {isThereAnImage ? (
              <GatsbyImage
                image={imageGetter}
                alt={product?.productImages[0]?.alternativeText}
              />
            ) : (
              <StaticImage
                width={220}
                height={220}
                style={{ border: "1px solid black" }}
                src="../assets/static-images/product-placeholder.jpg"
              ></StaticImage>
            )}

            <h3>{product.name}</h3>
            <button>View Product</button>
          </CardStyle>
        );
      })}
    </>
  );
}
